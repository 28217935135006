import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../components/antd/less/antMotionStyle.less'
import TraceSourceMainImg from './../../images/trace-source-1.jpg'

export default class TraceSource extends React.PureComponent {
  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={1}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='核心技术-区块链溯源' />
        <div className='core-tech-wrap trace-source-wrap'>
          <div className='main-img'></div>
          <div className='main-con'>
            <h2>物理纹理识别技术</h2>
            <div className='trace-source'>
              <img src={TraceSourceMainImg} alt='物理纹理识别技术' />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    strapi {
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
